import React, { useState } from 'react';
import logo from './images/promovere_logo.png';
import logomobile from './images/promovere_icon.svg';
import linkedin from './images/linkedin_promovere.svg';
import btn_demo from './images/request_demo_btn.svg';
import './App.css';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    companyName: '',
    phoneNumber: '',
    message: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.text();

      if (response.ok) {
        console.log(`Email sent successfully: ${data}`);
        setFormSubmitted(true);
      } else {
        console.log(`Failed to send email: ${data}`);
        setFormSubmitted(true); // HAHAH CHANGE THIS XD
      }
    } catch (error: any) {
      console.error(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div>
      <header className="lg:container mx-[27px] lg:mx-auto py-[34px] sm:py-[60px]">
        <a href="/">
          <img src={logo} className="w-[205px] hidden sm:block" alt="Promovere Logo" />
          <img src={logomobile} className="w-[36px] block sm:hidden" alt="Promovere Logo" />
        </a>
      </header>
      {formSubmitted ? (
        <div className="flex flex-col container mx-auto text-center">
          <h5 className="uppercase text-[#309BAB] font-[400] text-[20px] leading-normal sm:text-[26px] sm:leading-normal">
            Thank You!
          </h5>
          <h4 className="font-[600] text-[32px] leading-[48px] sm:text-[54px] sm:leading-[50px] lg:leading-normal">
            We will get in touch with you soon.
          </h4>
          <div className="flex flex-col items-center justify-center w-full">
            <a
              href="/"
              className="lg:my-6 mt-6  text-white text-[20px] leading-5 font-[400] bg-gradient-to-r from-[#309BAB] to-[#8E39AC] py-[18px] px-[42px] rounded-full flex flex-row gap-5 items-center"
            >
              Return to Homepage <img className="arrow-animate" src={btn_demo} alt="Return to homepage" />
            </a>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col container mx-auto">
            <div id="title" className="flex flex-col text-center justify-center items-center">
              <h5 className="uppercase text-[#309BAB] font-[400] text-[20px] leading-normal sm:text-[26px] sm:leading-normal">
                Contact us
              </h5>
              <h4 className="font-[600] text-[32px] leading-[48px] sm:text-[54px] sm:leading-[50px] lg:leading-normal">
                Drop us a message and get your free trial!
              </h4>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-[25px] container mx-auto w-full sm:w-1/2 sm:min-w-[580px] px-5 my-20 items-center justify-center"
          >
            <div className="flex flex-col lg:flex-row  gap-[25px] w-full">
              <div className="flex flex-col items-start gap-1 w-full">
                <p className="text-sm mx-[25px]">
                  Full name <span className="text-[#8E39AC] ">*</span>
                </p>
                <input
                  type="text"
                  name="fullName"
                  placeholder="John Doe"
                  required
                  onChange={handleChange}
                  className="text-[16px] leading-[50px] px-[28px] rounded-full  w-full "
                />
              </div>
              <div className="flex flex-col items-start gap-1 w-full">
                <p className="text-sm mx-[25px]">
                  Email address <span className="text-[#8E39AC] ">*</span>
                </p>
                <input
                  type="email"
                  name="email"
                  placeholder="email@email.com"
                  required
                  onChange={handleChange}
                  className="text-[16px] leading-[50px] px-[28px] rounded-full  w-full "
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row  gap-[25px] w-full">
              <div className="flex flex-col items-start gap-1 w-full">
                <p className="text-sm mx-[25px]">Company name</p>
                <input
                  type="text"
                  name="companyName"
                  placeholder="Company LTD"
                  onChange={handleChange}
                  className="text-[16px] leading-[50px] px-[28px] rounded-full  w-full "
                />
              </div>
              <div className="flex flex-col items-start gap-1 w-full">
                <p className="text-sm mx-[25px]">Phone number</p>
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="00123456789"
                  onChange={handleChange}
                  className="text-[16px] leading-[50px] px-[28px] rounded-full  w-full "
                />
              </div>
            </div>

            <div className="flex flex-col items-start gap-1 w-full">
              <p className="text-sm mx-[25px]">Message</p>
              <textarea
                name="message"
                placeholder="Your message here..."
                onChange={handleChange}
                className="text-[16px] leading-normal py-[16px] px-[28px] rounded-[30px] w-full h-[240px]"
              ></textarea>
            </div>
            <div className="w-full flex flex-row items-center justify-center lg:items-end lg:justify-end">
              <button
                type="submit"
                className="lg:my-6 mt-6 text-white text-[20px] leading-5 font-[400] bg-gradient-to-r from-[#309BAB] to-[#8E39AC] py-[18px] px-[42px] rounded-full flex flex-row gap-5 items-center"
              >
                Send
                <img className="arrow-animate" src={btn_demo} alt="Request a demo" />
              </button>
            </div>
          </form>
        </>
      )}
      <footer className="flex flex-col justify-center sm:pt-[100px] mt-[68px] mb-4 border-[3px] border-t-0 border-r-[#EEEEEE] border-b-[#EBEBEB] border-white rounded-[30px]">
        <div className="container mx-auto">
          <div
            id="footerlinks"
            className="flex flex-col sm:flex-row gap-[96px] items-center sm:items-start sm:justify-between pt-[40px] pb-[96px] sm:py-12 border-b border-[#309BAB] px-12"
          >
            <a href="/">
              <img src={logo} className="w-[205px] sm:w-[257px]" alt="Promovere Logo" />
            </a>
            <div id="socials" className="flex flex-col gap-6">
              <a href="https://www.linkedin.com/company/promovere-ai/" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} className="" alt="Linkedin Promovere" />
              </a>
            </div>
          </div>
          <p id="copyright" className="w-full pt-6 pb-12 text-center text-[14px] text-[#21272A] leading-5">
            Promovere.ai @ 2023. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
