import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Contact from './Contact';
import logo from './images/promovere_logo.png';
import logomobile from './images/promovere_icon.svg';
import hero_bg from './images/hero_bg.webp';
import hero_bg_mobile from './images/Bg-Image-Mobile.webp';
import hero_bg_tablet from './images/Bg-Image-Tablet.webp';
import hero_bg_web from './images/Bg-Image-Web.webp';
import btn_demo from './images/request_demo_btn.svg';
import video1 from './videos/video1.mp4';
import video2 from './videos/video2.mp4';
import video3 from './videos/video3.mp4';
import features_ball from './images/features_ball.webp';
import features_ball_1_mobile_tablet from './images/Features-1-Tablet.webp';
import features_ball_2_mobile_tablet from './images/Features-2-Tablet.webp';
import linkedin from './images/linkedin_promovere.svg';

import './App.css';

// MAIL SEND NA CONTACT@PROMOVERE.AI
// CAROUSEL SETTINGS START
// note: make sure you have the Carousel Styles and the Node Declarations imported
// carousel images import
const imageContext = require.context('./images/carousel', false, /\.(jpg|jpeg|png|svg)$/);
const images = imageContext.keys().map(imageContext);

// Calculate the number of images and the total width
const numberOfImages: number = images.length;
const slideWidth: number = 250; // 250px is the width of one image
const gap: number = 40; // 40px is the gap between slides
const animationspeed: number = 60; //This is the speed of which the carousel moves (in seconds)

const totalWidth: number = numberOfImages * slideWidth + numberOfImages * gap;

// Set CSS variables
document.documentElement.style.setProperty('--number-of-images', numberOfImages.toString());
document.documentElement.style.setProperty('--total-width', `${totalWidth}px`);
document.documentElement.style.setProperty('--gap', `${gap}px`);
document.documentElement.style.setProperty('--animationspeed', `scroll ${animationspeed}s linear infinite`);

// CAROUSEL SETTINGS END

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/"
          element={
            <div className="bg-[#E7E7E7] font-sans text-[#21272A] ">
              <div
                className=" absolute top-[130px] sm:top-0 right-0 w-[80%] h-[1000px] sm:w-[50%] sm:h-[1300px] bg-cover bg-no-repeat bg-center animate-hero hidden 2xl:block notfullhd"
                style={{ backgroundImage: `url(${hero_bg_web})` }}
              />
              <div
                className=" absolute top-[130px] sm:top-0 right-0 w-[80%] h-[1000px] sm:w-[50%] sm:h-[1300px] bg-cover bg-no-repeat bg-center animate-hero hidden fullhd"
                style={{ backgroundImage: `url(${hero_bg})` }}
              />
              {/* This needs to be fixed on mobile and tablet responsive, it goes on the right too much , VERY COOL BUG TO FIX :)  */}
              <div className="absolute top-[380px] right-0 w-full h-[1000px] sm:w-[50%] sm:h-[1300px] hidden fullhd">
                <div className="animate-hero">
                  <div className="circle-container inline-block left-[31%]">
                    <div className="circle circle1"></div>
                    <div className="circle circle2"></div>
                    <div className="circle circle3"></div>
                  </div>
                </div>
              </div>
              {/* mobile hero bg */}
              <div
                className=" absolute top-[130px] right-0 w-full h-[1000px] bg-contain bg-right bg-no-repeat animate-hero sm:hidden"
                style={{ backgroundImage: `url(${hero_bg_mobile})` }}
              />
              {/* tablet hero bg */}
              <div
                className=" absolute top-[130px] sm:top-0 right-0 w-full h-[1050px] bg-contain bg-no-repeat bg-right  hidden sm:block  2xl:hidden  animate-hero"
                style={{ backgroundImage: `url(${hero_bg_tablet})` }}
              />

              <header className="lg:container mx-[27px] lg:mx-auto py-[34px] sm:py-[60px]">
                <a href="/">
                  <img src={logo} className="w-[205px] hidden sm:block" alt="Promovere Logo" />
                  <img src={logomobile} className="w-[36px] block sm:hidden" alt="Promovere Logo" />
                </a>
              </header>
              {/* HERO */}
              <div
                id="hero"
                className="lg:container mx-[20px] sm:mx-[42px] lg:mx-auto flex flex-col gap-[180px] sm:gap-[200px] lg:gap-[150px] mt-[60px] sm:mt-[100px] 2xl:mt-[220px] relative"
              >
                <div id="hero_info" className=" sm:w-[70%] lg:w-[43%] sm:mx-[60px]">
                  <h1 className="font-bold text-[100px]  leading-[100px] xs:text-[120px] xs:leading-[140px] lg:text-[162px] lg:leading-[160px] uppercase">
                    ATLAS
                  </h1>
                  <h3 className=" text-[38px] sm:text-5xl font-[300] leading-[57px] sm:leading-[72px]">
                    The <span className="italic font-[400]">revolution of AI </span>applied to the{' '}
                    <span className="italic">promotional industry</span>{' '}
                  </h3>
                  <Link to="/contact">
                    <button className="my-6 text-white text-[20px] leading-5 font-[400] bg-gradient-to-r from-[#309BAB] to-[#8E39AC] py-[18px] px-[30px] rounded-full flex flex-row gap-5 items-center">
                      Request a demo
                      <img className="arrow-animate" src={btn_demo} alt="Request a demo" />
                    </button>
                  </Link>
                </div>

                <div
                  id="meet_atlas"
                  className="flex flex-col items-center justify-center px-[30px] py-[92px] lg:py-[118px] lg:px-[202px] gap-[31px] text-center backdrop-blur-lg rounded-[50px]"
                  style={{
                    boxShadow: '0px 4px 24px -1px #00000033',
                    background:
                      'linear-gradient(110deg, rgba(255, 255, 255, 0.40) 10.58%, rgba(255, 255, 255, 0.10) 75.87%)',
                  }}
                >
                  <div className="flex flex-col items-center justify-center">
                    <h6 className="uppercase text-[#8E39AC] text-[18px] leading-[27px] sm:text-[20px] sm:leading-[30px] ">
                      YOUR PERSONAL ASSISTANT
                    </h6>
                    <h4 className="font-[600] text-[32px] sm:text-[42px] leading-[50px] sm:w-[70%] lg:w-auto">
                      Meet Atlas - your all in 1 promotional assistant
                    </h4>
                  </div>
                  <p className="text-lg pb-[20px] px-[18px] sm:px-0">
                    Generative AI bot that creates personalized promotional materials on demand, revolutionizing the way
                    you promote products and services.{' '}
                  </p>
                </div>
              </div>
              {/* WHY ATLAS */}
              <div
                id="why_atlas"
                className=" mt-[150px] mb-[50px] lg:mt-[220px] flex flex-col gap-[75px] sm:gap-[120px] lg:gap-[140px] container mx-auto sm:mb-[130px] lg:mb-[240px]"
              >
                <div id="title" className="flex flex-col text-center justify-center items-center">
                  <h5 className="uppercase text-[#309BAB] font-[300] text-[20px] leading-normal sm:text-[26px] sm:leading-normal">
                    WHY ATLAS?
                  </h5>
                  <h4 className="font-[600] text-[32px] leading-[48px] sm:text-[54px] sm:leading-[50px]">Why Atlas</h4>
                </div>

                <div id="card1" className="flex flex-col-reverse lg:flex-row items-center gap-[30px] mx-[10px] sm:mx-0">
                  <div
                    id="video"
                    className="lg:w-1/2 p-[11px] sm:p-[22px] rounded-[18px] sm:rounded-[30px] sm:mx-[90px] lg:mx-0"
                    style={{ boxShadow: '0px 4px 24px -1px #00000033' }}
                  >
                    <video loop playsInline autoPlay muted width="100%" className="rounded-[18px] sm:rounded-[30px]">
                      <source src={video1} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div
                    id="details"
                    className="lg:w-1/2 lg:pl-[148px] flex flex-col items-center text-center lg:items-start lg:text-left gap-[20px]"
                  >
                    <h4 className="font-[600]  text-[32px] sm:text-[42px] leading-[50px]">Products selection</h4>
                    <p className="text-[20px] sm:text-[22px] font-[400] leading-[38px] sm:w-[67%] lg:w-auto">
                      With a million promotional products, finding the right one is hours of research, Atlas can shorten
                      that to minutes. We have already trained Atlas as an expert in 300.000 products and the number
                      continues to grow.
                    </p>
                  </div>
                </div>

                <div id="card2" className="flex flex-col lg:flex-row items-center gap-[30px] mx-[10px] sm:mx-0">
                  <div
                    id="details"
                    className="lg:w-1/2 lg:pr-[148px] flex flex-col items-center text-center lg:items-start lg:text-left gap-[20px] "
                  >
                    <h4 className="font-[600]  text-[32px] sm:text-[42px] leading-[50px]">Decoration visualisation</h4>
                    <p className="text-[20px] sm:text-[22px] font-[400] leading-[38px] sm:w-[67%] lg:w-auto">
                      Need to present how the product looks with the customer logo, in the right technique? No need to
                      spend hours in photoshop, Atlas generates the right decoration and look and feel on the product in
                      seconds.
                    </p>
                  </div>
                  <div
                    id="video"
                    className="lg:w-1/2 p-[11px] sm:p-[22px] rounded-[18px] sm:rounded-[30px] sm:mx-[90px] lg:mx-0"
                    style={{ boxShadow: '0px 4px 24px -1px #00000033' }}
                  >
                    <video loop playsInline autoPlay muted width="100%" className="rounded-[18px] sm:rounded-[30px] ">
                      <source src={video2} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div id="card3" className="flex flex-col-reverse lg:flex-row items-center gap-[30px] mx-[10px] sm:mx-0">
                  <div
                    id="video"
                    className="lg:w-1/2 p-[11px] sm:p-[22px] rounded-[18px] sm:rounded-[30px] sm:mx-[90px] lg:mx-0"
                    style={{ boxShadow: '0px 4px 24px -1px #00000033' }}
                  >
                    <video loop playsInline autoPlay muted width="100%" className="rounded-[18px] sm:rounded-[30px]">
                      <source src={video3} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div
                    id="details"
                    className="lg:w-1/2 lg:pl-[148px] flex flex-col items-center text-center lg:items-start lg:text-left gap-[20px]"
                  >
                    <h4 className="font-[600]  text-[32px] sm:text-[42px] leading-[50px]">Creating presentation</h4>
                    <p className="text-[20px] sm:text-[22px] font-[400] leading-[38px] sm:w-[67%] lg:w-auto">
                      Want to impress the customer with all the details and professional presentation? Atlas generates
                      customized presentations including product details, decorations and look and feel with 1 click
                      only.
                    </p>
                  </div>
                </div>
              </div>
              {/* FEATURES DESKTOP 1620+*/}
              <div
                id="features"
                className="hidden 2xl:flex items-center flex-row justify-center w-full mb-[160px] overflow-hidden"
              >
                <div id="conversational" className="relative top-0 w-0 scale-90 flex  flex-col gap-[48px] z-10 ">
                  <div
                    id="title"
                    className="flex flex-col text-left justify-start items-start relative w-[400px] left-[40px]"
                  >
                    <h5 className="uppercase text-[#8e39ac] text-[26px] font-[300] leading-normal">Conversational</h5>
                    <h4 className="font-[600] text-[54px] leading-[50px]">Features</h4>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative  right-[150px] w-[400px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Based on ChatGPT 4</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">Atlas speaks natural language</p>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative  right-[230px] w-[400px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Multilingual</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">
                      Where ever your dealers are, Atlas speaks in their language
                    </p>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative  right-[270px] w-[400px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Understanding intents</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">
                      As a trained professional, Atlas speaks in their language
                    </p>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative right-[230px]  w-[350px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Neural search</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">
                      Atlas needs only specifics to offer you the right product
                    </p>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative right-[150px]  w-[600px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Deduction skills</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">Atlas can resolve complex questions</p>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative right-[80px]  w-[650px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Memory</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">Atlas remembers your previous selections,</p>
                    <p className="text-[24px] font-[300] leading-[20px]">
                      tailoring the product discovery to your preferences
                    </p>
                  </div>
                </div>

                <div id="features_img" className="w-[1100px]">
                  <div
                    className=" w-full h-[1100px] bg-cover bg-no-repeat bg-center animate-features"
                    style={{ backgroundImage: `url(${features_ball})` }}
                  >
                    <div className="pl-60 pt-80 scale-[70%]">
                      <div className="animate-features">
                        <div className="circle-container">
                          <div className="circle circle1"></div>
                          <div className="circle circle2"></div>
                          <div className="circle circle3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="visual" className=" relative top-[10px] w-0 flex  flex-col gap-[90px] z-10">
                  <div
                    id="title"
                    className="flex flex-col text-left justify-start items-start relative w-[400px]   right-[130px]"
                  >
                    <h5 className="uppercase text-[#8e39ac] text-[26px] font-[300] leading-normal">Visual</h5>
                    <h4 className="font-[600] text-[54px] leading-[50px]">Features</h4>
                  </div>

                  <div id="feature" className="flex flex-col gap-2 relative   w-[400px] right-[60px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Based on Stable Diffusion XL</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">Atlas uses best available vision model</p>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative   w-[350px] right-[35px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Decoration techniques</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">
                      Multiple variations of the decoration for you to chose
                    </p>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative   w-[400px] right-[110px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Augmentation on a person</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">
                      Present your decorated garments on a variety of persons and backgrounds{' '}
                    </p>
                  </div>
                  <div id="feature" className="flex flex-col gap-2 relative   w-[400px] right-[230px]">
                    <h5 className="font-[600] text-[28px] leading-[33px]">Presentation creation</h5>
                    <p className="text-[24px] font-[300] leading-[33px]">
                      Atlas is trained to offer variety of presentations with your branding
                    </p>
                  </div>
                </div>
              </div>

              {/* FEATURES TABLET AND SMALL SCREENS */}

              <div
                id="features"
                className="hidden sm:flex 2xl:hidden items-center flex-col justify-center w-full mb-[160px] gap-[130px] "
              >
                <div id="conversational_wrapper" className="flex w-full items-start justify-center">
                  <div
                    id="conversational"
                    className="relative flex  flex-col gap-[48px] z-10 items-center w-full mx-auto"
                  >
                    <div
                      id="title"
                      className="flex flex-col text-left justify-start items-center relative w-[400px] lef-[40px]"
                    >
                      <h5 className="uppercase text-[#8e39ac] text-[26px] font-[300] leading-normal">Conversational</h5>
                      <h4 className="font-[600] text-[54px] leading-[50px]">Features</h4>
                    </div>
                    <div className="flex flex-col gap-[48px] items-start  w-[90%] xl:w-[80%]">
                      <div id="feature" className="flex flex-col gap-2 relative ml-[13%] w-[400px]">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Based on ChatGPT 4</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">Atlas speaks natural language</p>
                      </div>
                      <div id="feature" className="flex flex-col gap-2 relative ml-[5%]  w-[400px]">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Multilingual</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">
                          Where ever your dealers are, Atlas speaks in their language
                        </p>
                      </div>
                      <div id="feature" className="flex flex-col gap-2 relative  w-[400px]">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Understanding intents</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">
                          As a trained professional, Atlas speaks in their language
                        </p>
                      </div>
                      <div id="feature" className="flex flex-col gap-2 relative ml-[5%]  w-[350px]">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Neural search</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">
                          Atlas needs only specifics to offer you the right product
                        </p>
                      </div>
                      <div id="feature" className="flex flex-col gap-2 relative ml-[13%]   md:w-[600px] ">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Deduction skills</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">Atlas can resolve complex questions</p>
                      </div>
                      <div id="feature" className="flex flex-col gap-2 relative ml-[20%]   md:w-[600px] lg:w-[650px]">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Memory</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">
                          Atlas remembers your previous selections, tailoring the product discovery to your preferences
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="absolute  bg-contain bg-no-repeat bg-right w-full h-[600px]  mt-[150px] md:h-[800px] md:mt-[100px] lg:mt-[0px] lg:h-[1000px] xl:h-[1150px] xl:mt-[-100px] z-0 animate-features"
                    style={{ backgroundImage: `url(${features_ball_1_mobile_tablet})` }}
                  />
                </div>
                <div id="visual-wrapper" className="flex w-full items-start justify-center">
                  <div id="visual" className="relative flex  flex-col gap-[48px] z-10 items-center  w-full mx-auto">
                    <div id="title" className="flex flex-col text-left justify-start items-center relative w-[400px] ">
                      <h5 className="uppercase text-[#8e39ac] text-[26px] font-[300] leading-normal">Visual</h5>
                      <h4 className="font-[600] text-[54px] leading-[50px]">Features</h4>
                    </div>
                    <div className="flex flex-col gap-[48px] items-end w-[90%] xl:w-[80%] xlSpecial:w-[80%]">
                      <div id="feature" className="flex flex-col gap-2 relative   w-[400px] ">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Based on Stable Diffusion XL</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">Atlas uses best available vision model</p>
                      </div>
                      <div id="feature" className="flex flex-col gap-2 relative mr-[3%]  w-[350px] ">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Decoration techniques</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">
                          Multiple variations of the decoration for you to chose
                        </p>
                      </div>
                      <div id="feature" className="flex flex-col gap-2 relative mr-[5%]  w-[400px] ">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Augmentation on a person</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">
                          Present your decorated garments on a variety of persons and backgrounds{' '}
                        </p>
                      </div>
                      <div id="feature" className="flex flex-col gap-2 relative mr-[10%]  w-[400px] ">
                        <h5 className="font-[600] text-[28px] leading-[33px]">Presentation creation</h5>
                        <p className="text-[24px] font-[300] leading-[33px]">
                          Atlas is trained to offer variety of presentations with your branding
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="absolute  bg-contain bg-no-repeat bg-left w-full h-[600px]  mt-[150px] md:h-[800px] md:mt-[100px] lg:mt-[0px] lg:h-[1000px] xl:h-[1150px] xl:mt-[-100px] z-0 animate-features"
                    style={{ backgroundImage: `url(${features_ball_2_mobile_tablet})` }}
                  />
                </div>
              </div>

              {/* FEATURES MOBILE */}

              <div id="features" className="flex items-center flex-col justify-center w-full mb-[160px] sm:hidden">
                <div id="conversational_wrapper" className="flex ">
                  <div
                    id="conversational"
                    className=" relative flex w-full flex-col gap-[48px] z-50 mx-[15px] pt-[270px] phoneLarge:mx-[30px]"
                  >
                    <div id="title" className="flex flex-col text-left justify-start items-start ">
                      <h5 className="uppercase text-[#8e39ac]  sm:text-[26px] font-[300] leading-normal">
                        Conversational
                      </h5>
                      <h4 className="font-[600] text-[54px] leading-[50px]">Features</h4>
                    </div>
                    <div id="feature" className="flex flex-col gap-2  ">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Based on ChatGPT 4</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Atlas speaks natural language
                      </p>
                    </div>
                    <div id="feature" className="flex flex-col gap-2  ">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Multilingual</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Where ever your dealers are, Atlas speaks in their language
                      </p>
                    </div>
                    <div id="feature" className="flex flex-col gap-2 ">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Understanding intents</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        As a trained professional, Atlas speaks in their language
                      </p>
                    </div>
                    <div id="feature" className="flex flex-col gap-2    ">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Neural search</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Atlas needs only specifics to offer you the right product
                      </p>
                    </div>
                    <div id="feature" className="flex flex-col gap-2">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Deduction skills</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Atlas can resolve complex questions
                      </p>
                    </div>
                    <div id="feature" className="flex flex-col gap-2 ">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Memory</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Atlas remembers your previous selections,
                      </p>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[20px]">
                        tailoring the product discovery to your preferences
                      </p>
                    </div>
                  </div>
                  <div
                    className="absolute right-0 bg-contain bg-no-repeat bg-right w-full h-[500px] heroBreakOne:h-[600px] z-0 animate-features"
                    style={{ backgroundImage: `url(${features_ball_1_mobile_tablet})` }}
                  />
                </div>

                <div id="visual_wrapper" className="flex ">
                  <div
                    id="visual"
                    className=" relative flex  flex-col gap-[90px] z-10 mx-[15px] pt-[270px] phoneLarge:mx-[30px]"
                  >
                    <div id="title" className="flex flex-col text-left justify-start items-start ">
                      <h5 className="uppercase text-[#8e39ac] text-[26px] font-[300] leading-normal">Visual</h5>
                      <h4 className="font-[600] text-[54px] leading-[50px]">Features</h4>
                    </div>

                    <div id="feature" className="flex flex-col gap-2">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">
                        Based on Stable Diffusion XL
                      </h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Atlas uses best available vision model
                      </p>
                    </div>
                    <div id="feature" className="flex flex-col gap-2">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Decoration techniques</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Multiple variations of the decoration for you to chose
                      </p>
                    </div>
                    <div id="feature" className="flex flex-col gap-2 ">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Augmentation on a person</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Present your decorated garments on a variety of persons and backgrounds{' '}
                      </p>
                    </div>
                    <div id="feature" className="flex flex-col gap-2 ">
                      <h5 className="font-[600] text-[24px] sm:text-[28px] leading-[33px]">Presentation creation</h5>
                      <p className="text-[22px] sm:text-[24px] font-[300] leading-[33px]">
                        Atlas is trained to offer variety of presentations with your branding
                      </p>
                    </div>
                  </div>
                  <div
                    className="absolute right-0 bg-contain bg-no-repeat bg-right w-full h-[500px] heroBreakOne:h-[600px] z-0 animate-features "
                    style={{ backgroundImage: `url(${features_ball_1_mobile_tablet})` }}
                  />
                </div>
              </div>

              {/* Tech behind it infinite carousel */}
              <div id="tech" className="flex flex-col gap-[30px] sm:gap-[90px] pb-[150px] lg:pb-[216px] ">
                <div id="title" className="flex flex-col text-left justify-center items-center">
                  <h4 className="font-[700] text-[32px] sm:text-[44px] leading-[62px]">Tech behind it</h4>
                </div>
                {/* CAROUSEL START */}
                <div className="slider">
                  <div className="slide-track ">
                    {images.map((image, index) => (
                      <div className="slide" key={image || index}>
                        <img className="object-scale-down" src={image} alt={`slide-${index}`} width={250} />
                      </div>
                    ))}
                    {/* Duplicate slides - dont ask why :) */}
                    {images.map((image, index) => (
                      <div className="slide " key={`duplicate-${image || index}`}>
                        <img className="object-scale-down" src={image} alt={`slide-${index}`} width={250} />
                      </div>
                    ))}
                  </div>
                </div>
                {/* CAROUSEL END */}
              </div>

              {/* Contact us */}

              <div
                id="contactus"
                className={`contactus flex flex-col lg:flex-row lg:items-center items-start justify-between py-[73px] px-[38px] lg:py-[65px] lg:px-[136px] gap-[31px] text-center rounded-[50px] lg:container lg:mx-auto mx-[5%]`}
              >
                <h4 className="font-[600] lg:w-[60%] text-[46px] leading-[61px] text-left">
                  Get a{' '}
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#309BAB] to-[#8E39AC]">
                    live demo
                  </span>{' '}
                  of what Atlas can do for your company!
                </h4>
                <Link to="/contact">
                  <button className="lg:my-6 text-white text-[20px] leading-5 font-[400] bg-gradient-to-r from-[#309BAB] to-[#8E39AC] py-[18px] px-[30px] rounded-full flex flex-row gap-5 items-center">
                    contact us
                    <img className="arrow-animate" src={btn_demo} alt="Request a demo" />
                  </button>
                </Link>
              </div>

              {/* footer */}
              <footer className="flex flex-col justify-center sm:pt-[100px] mt-[68px] mb-4 border-[3px] border-t-0 border-r-[#EEEEEE] border-b-[#EBEBEB] border-white rounded-[30px]">
                <div className="container mx-auto">
                  <div
                    id="footerlinks"
                    className="flex flex-col sm:flex-row gap-[96px] items-center sm:items-start sm:justify-between pt-[40px] pb-[96px] sm:py-12 border-b border-[#309BAB] px-12"
                  >
                    <a href="/">
                      <img src={logo} className="w-[205px] sm:w-[257px]" alt="Promovere Logo" />
                    </a>
                    <div id="socials" className="flex flex-col gap-6">
                      <a
                        href="https://www.linkedin.com/company/promovere-ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={linkedin} className="" alt="Linkedin Promovere" />
                      </a>
                    </div>
                  </div>
                  <p id="copyright" className="w-full pt-6 pb-12 text-center text-[14px] text-[#21272A] leading-5">
                    Promovere.ai @ 2023. All rights reserved.
                  </p>
                </div>
              </footer>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
